<script>
export default {
  created () {
    const url = 'https://contasinfinity.com.br/suporte/'

    const isApple = navigator.userAgent.match(
      /(iPad|iPhone|iPod)/g
    )

    if (isApple) {
      window.location.assign(url)
    }

    window.open(url, '_blank')

    this.$router.go(-1)
  }
}
</script>
